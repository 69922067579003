import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import bg1 from '../assets/images/hero/bg3.jpg';
import logo from '../assets/images/logo-dark.png';

import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import 'firebase/compat/auth';
import {
  Alert,
  handleGoogleError,
  isLoggedIn,
  isTokenExpired,
} from '../helpers/utils';

export default function ResetPassword() {
  const initialFormValues = {
    email: '',
  };

  const [formValues, setFormValues] = useState(initialFormValues);

  const auth = getAuth();

  const handlePasswordResetLink = (e) => {
    e.preventDefault();
    var actionCodeSettings = {
      // After password reset, the user will be give the ability to go back
      // to this page.
      url: process.env.PUBLIC_URL + '/login',
      handleCodeInApp: false,
    };
    console.log('actionCodeSettings :>> ', actionCodeSettings);
    sendPasswordResetEmail(auth, formValues.email, actionCodeSettings)
      .then(() => {
        Alert(
          'success',
          'Password Reset Link Sent to your email. Please check'
        );
        setFormValues(initialFormValues);
      })
      .catch((error) => {
        console.log('error.code :>> ', error.code);
        handleGoogleError(error);
      });
  };

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const redirectAfterLogin = async () => {
    window.location.href = `/`;
  };

  useEffect(() => {
    if (isLoggedIn() && !isTokenExpired()) {
      redirectAfterLogin();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <section
      className='bg-home d-flex align-items-center'
      style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'center' }}>
      <div className='bg-overlay bg-linear-gradient-2'></div>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-4 col-md-5 col-12'>
            <div
              className='p-4 bg-white rounded shadow-md mx-auto w-100'
              style={{ maxWidth: '400px' }}>
              <form onSubmit={handlePasswordResetLink}>
                <Link to='/'>
                  <img src={logo} className='mb-4 d-block mx-auto' alt='' />
                </Link>
                <h6 className='mb-2 text-uppercase fw-semibold'>
                  Reset your password
                </h6>

                <p className='text-muted'>
                  You will receive a link to create a new password via email.
                </p>

                <div className='mb-3'>
                  <label className='form-label fw-semibold'>Your Email</label>
                  <input
                    name='email'
                    id='email'
                    type='email'
                    className='form-control'
                    placeholder='Email'
                    value={formValues.email}
                    onChange={handleChange}
                    required
                  />
                </div>

                <button className='btn btn-primary w-100' type='submit'>
                  Send Password Reset Link
                </button>

                <div className='col-12 text-center mt-3'>
                  <span>
                    <span className='text-muted small me-2'>
                      Remember your password ?{' '}
                    </span>{' '}
                    <Link to='/login' className='text-dark fw-semibold small'>
                      Sign in
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
