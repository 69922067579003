import React from 'react';
import { Link } from 'react-router-dom';

import bg1 from '../assets/images/hero/bg.jpg';
import contact from '../assets/images/svg/contact.svg';

import { contactData } from '../data/data';

import Navbar from '../componants/navbar';
import Footer from '../componants/footer';
import ScrollTop from '../componants/scrollTop';

export default function ContactUs() {
  return (
    <>
      <Navbar navClass='defaultscroll sticky' navLight={true} />

      <section
        className='bg-half-170 d-table w-100'
        style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top' }}>
        <div className='bg-overlay bg-gradient-overlay'></div>
        <div className='container'>
          <div className='row mt-5 justify-content-center'>
            <div className='col-12'>
              <div className='title-heading text-center'>
                <h5 className='heading fw-semibold mb-0 sub-heading text-white title-dark'>
                  Contact us
                </h5>
              </div>
            </div>
          </div>

          <div className='position-middle-bottom'>
            <nav aria-label='breadcrumb' className='d-block'>
              <ul className='breadcrumb breadcrumb-muted mb-0 p-0'>
                <li className='breadcrumb-item'>
                  <Link to='/'>Coach & Hire</Link>
                </li>
                <li className='breadcrumb-item active' aria-current='page'>
                  Contact us
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
      <div className='position-relative'>
        <div className='shape overflow-hidden text-white'>
          <svg
            viewBox='0 0 2880 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z'
              fill='currentColor'></path>
          </svg>
        </div>
      </div>

      <section className='section pb-0'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-md-6'>
              <img src={contact} className='img-fluid' alt='' />
            </div>
            <div className='col-md-6'>
              <div className='p-4 rounded shadow ms-lg-5'>
                <h4>Get in touch !</h4>
                <form className='mt-4'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Your Name <span className='text-danger'>*</span>
                        </label>
                        <input
                          name='name'
                          id='name'
                          type='text'
                          className='form-control'
                          placeholder='Name :'
                        />
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Your Email <span className='text-danger'>*</span>
                        </label>
                        <input
                          name='email'
                          id='email'
                          type='email'
                          className='form-control'
                          placeholder='Email :'
                        />
                      </div>
                    </div>

                    <div className='col-12'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Subject
                        </label>
                        <input
                          name='subject'
                          id='subject'
                          className='form-control'
                          placeholder='Subject :'
                        />
                      </div>
                    </div>

                    <div className='col-12'>
                      <div className='mb-3'>
                        <label className='form-label fw-semibold'>
                          Comments <span className='text-danger'>*</span>
                        </label>
                        <textarea
                          name='comments'
                          id='comments'
                          rows='4'
                          className='form-control'
                          placeholder='Message :'></textarea>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='d-grid'>
                        <button
                          type='submit'
                          id='submit'
                          name='send'
                          className='btn btn-primary'>
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className='container mt-100 mt-60'>
          <div className='row g-4'>
            {contactData.map((item, index) => {
              let Icon = item.icon;
              return (
                <div className='col-md-4' key={index}>
                  <div className='position-relative features text-center mx-lg-4 px-md-1'>
                    <div className='feature-icon bg-soft-primary rounded shadow mx-auto position-relative overflow-hidden d-flex justify-content-center align-items-center'>
                      <Icon className='fea icon-ex-md' />
                    </div>

                    <div className='mt-4'>
                      <h5 className='mb-3'>{item.title}</h5>
                      <p className='text-muted'>{item.desc}</p>
                      <Link to={item.link} className='text-primary'>
                        {item.link}
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className='container-fluid mt-100 mt-60'>
          <div className='row'>
            <div className='col-12 p-0'>
              <div className='card map border-0'>
                <div className='card-body p-0'>
                  <iframe
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin'
                    title='Coach & Hire'
                    style={{ border: '0' }}
                    allowFullScreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </>
  );
}
