import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { clientConfig } from './config/clientConfig';

export const { FIREBASE_DEV_CONFIG, FIREBASE_PROD_CONFIG } = clientConfig;

// Initialize Firebase
const app = initializeApp(
  process.env.REACT_APP_ENV === 'production'
    ? FIREBASE_PROD_CONFIG
    : FIREBASE_DEV_CONFIG
);
getAnalytics(app);
