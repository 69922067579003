import AxiosInstance from '../../helpers/AxiosInstance';
import { Alert, isLoggedIn, isTokenExpired } from '../../helpers/utils';
const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const placeOrder = createAsyncThunk(
  'post/order',
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosInstance.post(`order`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const getOrders = createAsyncThunk(
  'get/orders',
  async (data, { rejectWithValue }) => {
    try {
      if (isLoggedIn() && !isTokenExpired()) {
        const response = await AxiosInstance.get(`order/my-orders`);
        return response.data;
      } else {
        return {
          data: [],
        };
      }
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getProfile = createAsyncThunk(
  'get/profile',
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosInstance.get(`user/profile`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const updateProfile = createAsyncThunk(
  'put/profile',
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosInstance.put(`user`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const userSlice = createSlice({
  name: 'users',
  initialState: {
    status: 'idle',
    data: [],
    profileStatus: 'idle',
    profile: {},
  },
  reducers: {},
  extraReducers: {
    [placeOrder.pending]: (state, action) => {
      state.placeOrderStatus = 'loading';
    },
    [placeOrder.fulfilled]: (state, action) => {
      state.placeOrderStatus = 'fulfilled';
      // Alert('success', `${action.payload.msg}`);
    },
    [placeOrder.rejected]: (state, action) => {
      state.placeOrderStatus = 'error';
      Alert('error', `${action.payload.data.msg}`);
    },
    [getOrders.pending]: (state, action) => {
      state.status = 'loading';
    },
    [getOrders.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      // Alert('success', `${action.payload.msg}`);
      const addresses = action.payload.data.map((order) => {
        return {
          firstName: order.firstName,
          lastName: order.lastName,
          email: order.email,
          phoneNo: order.phoneNo,
          doorNo: order.doorNo,
          city: order.city,
          state: order.state,
          pinCode: order.pinCode,
        };
      });

      const uniqueAddresses = Array.from(
        new Set(addresses.map(JSON.stringify))
      ).map(JSON.parse);

      state.addresses = uniqueAddresses;

      state.orders = action.payload.data;
    },
    [getOrders.rejected]: (state, action) => {
      state.status = 'error';
      Alert('error', `${action.payload.data.msg}`);
    },
    [getProfile.pending]: (state, action) => {
      state.profileStatus = 'loading';
    },
    [getProfile.fulfilled]: (state, action) => {
      state.profileStatus = 'fulfilled';
      state.profile = action.payload.data;
    },
    [getProfile.rejected]: (state, action) => {
      state.profileStatus = 'error';
      Alert('error', `${action.payload.data.msg}`);
    },
    [updateProfile.fulfilled]: (state, action) => {
      Alert('success', `${action.payload.msg}`);
    },
    [updateProfile.rejected]: (state, action) => {
      Alert('error', `${action.payload.data.msg}`);
    },
  },
});

export default userSlice.reducer;
