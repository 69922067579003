import AxiosInstance from '../../helpers/AxiosInstance';
import { Alert } from '../../helpers/utils';
const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const sendRequest = createAsyncThunk(
  'send/request',
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosInstance.post(`followers`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const getFollowers = createAsyncThunk(
  'get/followers',
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosInstance.get(
        `followers/view-followers`,
        data
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getRequests = createAsyncThunk(
  'get/requests',
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosInstance.get(`followers/view-requests`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateRequest = createAsyncThunk(
  'update/request',
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosInstance.put(`followers/${data.id}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// export const sendRequest = createAsyncThunk(
//   'send/request',
//   async (data, { rejectWithValue }) => {
//     try {
//       const response = await AxiosInstance.post(`followers`, data);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response);
//     }
//   }
// );

const followerSlice = createSlice({
  name: 'coach',
  initialState: {
    status: 'idle',
    data: [],
    followerStatus: 'idle',
    followers: [],
    followRequestStatus: 'idle',
    followRequests: [],
  },
  reducers: {},
  extraReducers: {
    [sendRequest.pending]: (state, action) => {
      state.status = 'loading';
    },
    [sendRequest.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      Alert('success', `${action.payload.msg}`);
    },
    [sendRequest.rejected]: (state, action) => {
      state.status = 'error';
      Alert('error', `${action.payload.data.msg}`);
    },
    [updateRequest.fulfilled]: (state, action) => {},
    [updateRequest.rejected]: (state, action) => {
      Alert('error', `${action.payload.data.msg}`);
    },

    [getFollowers.pending]: (state, action) => {
      state.followerStatus = 'loading';
    },
    [getFollowers.fulfilled]: (state, action) => {
      state.followerStatus = 'fulfilled';
      state.followers = action.payload.data;
    },
    [getFollowers.rejected]: (state, action) => {
      state.followerStatus = 'error';
      Alert('error', `${action.payload.data.msg}`);
    },
    [getRequests.pending]: (state, action) => {
      state.followRequestStatus = 'loading';
    },
    [getRequests.fulfilled]: (state, action) => {
      state.followRequestStatus = 'fulfilled';
      state.followRequests = action.payload.data;
    },
    [getRequests.rejected]: (state, action) => {
      state.followRequestStatus = 'error';
      Alert('error', `${action.payload.data.msg}`);
    },
  },
});

export default followerSlice.reducer;
