import company1 from '../assets/images/company/facebook-logo.png';
import company2 from '../assets/images/company/google-logo.png';
import company3 from '../assets/images/company/android.png';
import company4 from '../assets/images/company/lenovo-logo.png';
import company5 from '../assets/images/company/spotify.png';
import company6 from '../assets/images/company/linkedin.png';
import company7 from '../assets/images/company/circle-logo.png';
import company8 from '../assets/images/company/skype.png';
import company9 from '../assets/images/company/snapchat.png';
import company10 from '../assets/images/company/shree-logo.png';
import company11 from '../assets/images/company/telegram.png';
import company12 from '../assets/images/company/whatsapp.png';

import blog1 from '../assets/images/blog/01.jpg';
import blog2 from '../assets/images/blog/02.jpg';
import blog3 from '../assets/images/blog/03.jpg';
import blog4 from '../assets/images/blog/04.jpg';
import blog5 from '../assets/images/blog/05.jpg';
import blog6 from '../assets/images/blog/06.jpg';
import blog7 from '../assets/images/blog/07.jpg';
import blog8 from '../assets/images/blog/08.jpg';
import blog9 from '../assets/images/blog/09.jpg';

import work1 from '../assets/images/work/01.jpg';
import work2 from '../assets/images/work/02.jpg';
import work3 from '../assets/images/work/03.jpg';
import work4 from '../assets/images/work/04.jpg';
import work5 from '../assets/images/work/05.jpg';
import work6 from '../assets/images/work/06.jpg';

import team1 from '../assets/images/team/01.jpg';
import team2 from '../assets/images/team/02.jpg';
import team3 from '../assets/images/team/03.jpg';
import team4 from '../assets/images/team/04.jpg';
import team5 from '../assets/images/team/05.jpg';
import team6 from '../assets/images/team/06.jpg';
import team7 from '../assets/images/team/07.jpg';
import team8 from '../assets/images/team/08.jpg';

import {
  FiAirplay,
  FiAward,
  FiAtSign,
  FiCodesandbox,
  FiChrome,
  FiCopy,
  FiCpu,
  FiPhone,
  FiActivity,
  FiClock,
  FiFileText,
  FiUsers,
  FiUser,
  FiInbox,
  FiThumbsUp,
  FiUmbrella,
  FiHelpCircle,
  FiBookmark,
  FiSettings,
  FiMail,
  FiMapPin,
} from '../assets/icons/vander';

export const categoriesData = [
  {
    icon: FiAirplay,
    title: 'Business',
    title2: 'Development',
    jobs: '74 Jobs',
  },
  {
    icon: FiAward,
    title: 'Marketing &',
    title2: 'Communication',
    jobs: '20 Jobs',
  },
  {
    icon: FiAtSign,
    title: 'Project',
    title2: 'Management',
    jobs: '35 Jobs',
  },
  {
    icon: FiCodesandbox,
    title: 'Customer',
    title2: 'Service',
    jobs: '46 Jobs',
  },
  {
    icon: FiChrome,
    title: 'Software',
    title2: 'Engineering',
    jobs: '60 Jobs',
  },
  {
    icon: FiCopy,
    title: 'Human Resource',
    title2: 'HR',
    jobs: '74 Jobs',
  },
  {
    icon: FiCpu,
    title: 'It &',
    title2: 'Networking',
    jobs: '20 Jobs',
  },
  {
    icon: FiInbox,
    title: 'Sales &',
    title2: 'Marketing',
    jobs: '35 Jobs',
  },
  {
    icon: FiThumbsUp,
    title: 'Project',
    title2: 'Manager',
    jobs: '46 Jobs',
  },
  {
    icon: FiUmbrella,
    title: 'Data',
    title2: 'Science',
    jobs: '60 Jobs',
  },
];

export const jobData = [
  {
    id: 1,
    image: company1,
    name: 'Facebook',
    title: 'Web Designer / Developer',
    country: 'Australia',
    city: 'Vienna',
    applied: '20',
    vacancy: '40',
    date: '13th Sep 2023',
    salary: '950 - 1100',
    posted: '2',
    jobTime: 'Full Time',
    desc: 'Looking for an experienced Web Designer for an our company.',
  },
  {
    id: 2,
    image: company2,
    name: 'Google',
    title: 'Marketing Director',
    country: 'Russia',
    city: 'Moscow',
    applied: '20',
    vacancy: '40',
    date: '29th Nov 2023',
    salary: '950 - 1100',
    posted: '5',
    jobTime: 'Part Time',
    desc: 'Looking for an experienced Web Designer for an our company.',
  },
  {
    id: 3,
    image: company3,
    name: 'Android',
    title: 'Application Developer',
    country: 'Germany',
    city: 'Berlin',
    applied: '20',
    vacancy: '40',
    date: '29th Dec 2023',
    salary: '950 - 1100',
    posted: '3',
    jobTime: 'Remote',
    desc: 'Looking for an experienced Web Designer for an our company.',
  },
  {
    id: 4,
    image: company4,
    name: 'Lenovo',
    title: 'Senior Product Designer',
    country: 'Italy',
    city: 'Rome',
    applied: '20',
    vacancy: '40',
    date: '13th March 2023',
    salary: '950 - 1100',
    posted: '2',
    jobTime: 'WFH',
    desc: 'Looking for an experienced Web Designer for an our company.',
  },
  {
    id: 5,
    image: company5,
    name: 'Spotify',
    title: 'C++ Developer',
    country: 'France',
    city: 'Paris',
    applied: '20',
    vacancy: '40',
    date: '5th May 2023',
    salary: '950 - 1100',
    posted: '3',
    jobTime: 'Full Time',
    desc: 'Looking for an experienced Web Designer for an our company.',
  },
  {
    id: 6,
    image: company6,
    name: 'Linkedin',
    title: 'Php Developer',
    country: 'Greece',
    city: 'Athens',
    applied: '20',
    vacancy: '40',
    date: '19th June 2023',
    salary: '950 - 1100',
    posted: '7',
    jobTime: 'Remote',
    desc: 'Looking for an experienced Web Designer for an our company.',
  },
  {
    id: 7,
    image: company7,
    name: 'Circle CI',
    title: 'Web Designer / Developer',
    country: 'China',
    city: 'Beijing',
    applied: '20',
    vacancy: '40',
    date: '20th June 2023',
    salary: '950 - 1100',
    posted: '1',
    jobTime: 'Full Time',
    desc: 'Looking for an experienced Web Designer for an our company.',
  },
  {
    id: 8,
    image: company8,
    name: 'Skype',
    title: 'Marketing Director',
    country: 'Japan',
    city: 'Tokyo',
    applied: '20',
    vacancy: '40',
    date: '31th Aug 2023',
    salary: '950 - 1100',
    posted: '2',
    jobTime: 'Part Time',
    desc: 'Looking for an experienced Web Designer for an our company.',
  },
  {
    id: 9,
    image: company9,
    name: 'Snapchat',
    title: 'Application Developer',
    country: 'Singapore',
    city: 'Singapore',
    applied: '20',
    vacancy: '40',
    date: '1th Sep 2023',
    salary: '950 - 1100',
    posted: '4',
    jobTime: 'Remote ',
    desc: 'Looking for an experienced Web Designer for an our company.',
  },
  {
    id: 10,
    image: company10,
    name: 'Shreethemes',
    title: 'Senior Product Designer',
    country: 'United States',
    city: 'Washington',
    applied: '20',
    vacancy: '40',
    date: '13th May 2023',
    salary: '950 - 1100',
    posted: '3',
    jobTime: 'WFH',
    desc: 'Looking for an experienced Web Designer for an our company.',
  },
  {
    id: 11,
    image: company11,
    name: 'Telegram',
    title: 'C++ Developer',
    country: 'Spain',
    city: 'Madrid',
    applied: '20',
    vacancy: '40',
    date: '13th March 2023',
    salary: '950 - 1100',
    posted: '2',
    jobTime: 'Full Time',
    desc: 'Looking for an experienced Web Designer for an our company.',
  },
  {
    id: 12,
    image: company12,
    name: 'Whatsapp',
    title: 'Php Developer',
    country: 'Jordan',
    city: 'Amman',
    applied: '20',
    vacancy: '40',
    date: '13th June 2023',
    salary: '950 - 1100',
    posted: '5',
    jobTime: 'Remote',
    desc: 'Looking for an experienced Web Designer for an our company.',
  },
];

export const servicesData = [
  {
    icon: FiPhone,
    title: '24/7 Support',
    desc: 'Many desktop publishing now use and a search for job.',
  },
  {
    icon: FiCpu,
    title: 'Tech & Startup Jobs',
    desc: 'Many desktop publishing now use and a search for job.',
  },
  {
    icon: FiActivity,
    title: 'Quick & Easy',
    desc: 'Many desktop publishing now use and a search for job.',
  },
  {
    icon: FiClock,
    title: 'Save Time',
    desc: 'Many desktop publishing now use and a search for job.',
  },
  {
    icon: FiFileText,
    title: 'Apply with confidence',
    desc: 'Many desktop publishing now use and a search for job.',
  },
  {
    icon: FiCodesandbox,
    title: 'Reduce Hiring Bias',
    desc: 'Many desktop publishing now use and a search for job.',
  },
  {
    icon: FiUsers,
    title: 'Proactive Employers',
    desc: 'Many desktop publishing now use and a search for job.',
  },
  {
    icon: FiUser,
    title: 'Proactive Employers',
    desc: 'Many desktop publishing now use and a search for job.',
  },
];

export const blogData = [
  {
    id: 1,
    image: blog1,
    title: '11 Tips to Help You Get New Clients Through Cold Calling',
    date: '13th Sep 2023',
    time: '5 min read',
    tag: 'Arts',
    company: 'Google',
  },
  {
    id: 2,
    image: blog2,
    title: 'DigitalOcean launches first Canadian data centre in Toronto',
    date: '29th Nov 2023',
    time: '5 min read',
    tag: 'Illustration',
    company: 'Facebook',
  },
  {
    id: 3,
    image: blog3,
    title: 'Using Banner Stands To Increase Trade Show Traffic',
    date: '29th Dec 2023',
    time: '5 min read',
    tag: 'Music',
    company: 'Linkedin',
  },
  {
    id: 4,
    image: blog4,
    title: '11 Tips to Help You Get New Clients Through Cold Calling',
    date: '13th March 2023',
    time: '5 min read',
    tag: 'Arts',
    company: 'Google',
  },
  {
    id: 5,
    image: blog5,
    title: 'DigitalOcean launches first Canadian data centre in Toronto',
    date: '5th May 2023',
    time: '5 min read',
    tag: 'Illustration',
    company: 'Facebook',
  },
  {
    id: 6,
    image: blog6,
    title: 'Using Banner Stands To Increase Trade Show Traffic',
    date: '19th June 2023',
    time: '5 min read',
    tag: 'Music',
    company: 'Linkedin',
  },
  {
    id: 7,
    image: blog7,
    title: '11 Tips to Help You Get New Clients Through Cold Calling',
    date: '20th June 2023',
    time: '5 min read',
    tag: 'Arts',
    company: 'Google',
  },
  {
    id: 8,
    image: blog8,
    title: 'DigitalOcean launches first Canadian data centre in Toronto',
    date: '31st Aug 2023',
    time: '5 min read',
    tag: 'Illustration',
    company: 'Facebook',
  },
  {
    id: 9,
    image: blog9,
    title: 'Using Banner Stands To Increase Trade Show Traffic',
    date: '1st Sep 2024',
    time: '5 min read',
    tag: 'Music',
    company: 'Linkedin',
  },
];

export const recentBlog = [
  {
    image: blog1,
    title: 'Consultant Business',
    date: '13th March 2023',
  },
  {
    image: blog2,
    title: 'Grow Your Business',
    date: '5th May 2023',
  },
  {
    image: blog1,
    title: 'Look On The Glorious Balance',
    date: '19th June 2023',
  },
];

export const servicesTwo = [
  {
    image: work1,
    title: 'Product & Branding Design',
  },
  {
    image: work2,
    title: 'Wordpress Development',
  },
  {
    image: work3,
    title: 'Audio & Video Editing',
  },
  {
    image: work4,
    title: 'Admin & Customer Support',
  },
  {
    image: work5,
    title: 'UX / UI Designer',
  },
  {
    image: work6,
    title: 'Digital Marketing',
  },
];

export const categoriesTwoData = [
  {
    title: 'Human Resource',
    job: '90 Jobs Available',
  },
  {
    title: 'It & Networking',
    job: '90 Jobs Available',
  },
  {
    title: 'Sales & Marketing',
    job: '90 Jobs Available',
  },
  {
    title: 'Accounting',
    job: '90 Jobs Available',
  },
  {
    title: 'Delivery Boy',
    job: '90 Jobs Available',
  },
  {
    title: 'Data Science',
    job: '90 Jobs Available',
  },
  {
    title: 'Project Manager',
    job: '90 Jobs Available',
  },
  {
    title: 'Engineering',
    job: '90 Jobs Available',
  },
  {
    title: 'Help Center',
    job: '90 Jobs Available',
  },
  {
    title: 'Full Stack Developer',
    job: '90 Jobs Available',
  },
];

export const accordionData = [
  {
    title: 'How does it work ?',
    desc: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
  },
  {
    title: 'Do I need a designer to use Coach & Hire ?',
    desc: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
  },
  {
    title: 'What do I need to do to start selling ?',
    desc: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
  },
  {
    title: 'What happens when I receive an order ?',
    desc: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
  },
];

export const jobOpenings = [
  {
    title: 'Frontend Developer',
    position: 'Total Openings: 1',
  },
  {
    title: 'Backend Developer',
    position: 'Total Openings: 3',
  },
  {
    title: 'Web Developer',
    position: 'Total Openings: 2',
  },
];

export const candidatesData = [
  {
    id: 1,
    image: team1,
    name: 'Steven Townsend',
    post: 'Marketing Director',
    salary: '$5k - $6k',
    experience: '2 Years',
    rate: true,
  },
  {
    id: 2,
    image: team2,
    name: 'Tiffany Betancourt',
    post: 'Application Developer',
    salary: '$4k - $5k',
    experience: '1 Years',
    rate: false,
  },
  {
    id: 3,
    image: team3,
    name: 'Jacqueline Burns',
    post: 'Senior Product Designer',
    salary: '$3k - $4k',
    experience: '1 Years',
    rate: false,
  },
  {
    id: 4,
    image: team4,
    name: 'Mari Harrington',
    post: 'C++ Developer',
    salary: '$7k - $8k',
    experience: '3 Years',
    rate: true,
  },
  {
    id: 5,
    image: team5,
    name: 'Floyd Glasgow',
    post: 'Php Developer',
    salary: '$3k - $4k',
    experience: '2 Years',
    rate: false,
  },
  {
    id: 6,
    image: team6,
    name: 'Donna Schultz',
    post: 'Web Designer / Developer',
    salary: '$6k - $7k',
    experience: '3 Years',
    rate: false,
  },
  {
    id: 7,
    image: team7,
    name: 'Joshua Morris',
    post: 'Marketing Director',
    salary: '$3k - $4k',
    experience: '1 Years',
    rate: false,
  },
  {
    id: 8,
    image: team8,
    name: 'Rosaria Vargas',
    post: 'Application Developer',
    salary: '$5k - $6k',
    experience: '2 Years',
    rate: true,
  },
  {
    id: 9,
    image: team1,
    name: 'Steven Townsend',
    post: 'Marketing Director',
    salary: '$5k - $6k',
    experience: '2 Years',
    rate: false,
  },
  {
    id: 10,
    image: team2,
    name: 'Tiffany Betancourt',
    post: 'Application Developer',
    salary: '$4k - $5k',
    experience: '1 Years',
    rate: true,
  },
  {
    id: 11,
    image: team3,
    name: 'Jacqueline Burns',
    post: 'Senior Product Designer',
    salary: '$3k - $4k',
    experience: '1 Years',
    rate: true,
  },
  {
    id: 12,
    image: team4,
    name: 'Mari Harrington',
    post: 'C++ Developer',
    salary: '$7k - $8k',
    experience: '3 Years',
    rate: false,
  },
];
export const candidateSkill = [
  {
    title: 'HTML',
    value: '84%',
  },
  {
    title: 'CSS',
    value: '75%',
  },
  {
    title: 'JQuery',
    value: '79%',
  },
  {
    title: 'WordPress',
    value: '79%',
  },
  {
    title: 'Figma',
    value: '85%',
  },
  {
    title: 'Illustration',
    value: '65%',
  },
];

export const teamData = [
  {
    image: team1,
    name: 'Jack John',
    title: 'Job Seeker',
  },
  {
    image: team2,
    name: 'Krista John',
    title: 'Job Seeker',
  },
  {
    image: team3,
    name: 'Roger Jackson',
    title: 'Job Seeker',
  },
  {
    image: team4,
    name: 'Johnny English',
    title: 'Job Seeker',
  },
];
export const helpcenterData = [
  {
    icon: FiHelpCircle,
    title: 'FAQs',
    desc: 'The phrasal sequence of the is now so that many campaign and benefit',
  },
  {
    icon: FiBookmark,
    title: 'Guides / Support',
    desc: 'The phrasal sequence of the is now so that many campaign and benefit',
  },
  {
    icon: FiSettings,
    title: 'Support Request',
    desc: 'The phrasal sequence of the is now so that many campaign and benefit',
  },
];

export const commentsData = [
  {
    image: team1,
    name: 'Lorenzo Peterson',
    date: '13th March 2023 at 1:30 pm',
    desc: '" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "',
  },
  {
    image: team2,
    name: 'Tammy Camacho',
    date: '5th May 2023 at 10:00 am',
    desc: '" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "',
  },
  {
    image: team3,
    name: 'Tammy Camacho',
    date: '19th June 2023 at 9:00 am',
    desc: '" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "',
  },
  {
    image: team4,
    name: 'Lorenzo Peterson',
    date: '1st Sep 2023 at 1:30 pm',
    desc: '" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "',
  },
];
export const contactData = [
  {
    icon: FiPhone,
    title: 'Phone',
    desc: 'Start working with Coach & Hire that can provide everything',
    link: 'tel:+152534-468-854',
  },
  {
    icon: FiMail,
    title: 'Email',
    desc: 'Start working with Coach & Hire that can provide everything',
    link: 'contact@example.com',
  },
  {
    icon: FiMapPin,
    title: 'Location',
    desc: 'Start working with Coach & Hire that can provide everything',
    link: 'View on Google map',
  },
];
