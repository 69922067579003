import { clientConfig } from './clientConfig';

export const {
  LOCAL_API_URL,
  ANAND_PC,
  DEV_SERVER_API_URL,
  PROD_API_URL,
  TOKEN_KEY,
  DEFAULT_PRODUCT_IMAGE,
  RAZOR_PAY_KEY,
  PUSHER_INSTANCE_ID,
} = clientConfig;

export const API_URL =
  process.env.REACT_APP_ENV === 'production'
    ? PROD_API_URL
    : process.env.REACT_APP_ENV === 'dev-server'
    ? DEV_SERVER_API_URL
    : process.env.REACT_APP_ENV === 'anand-pc'
    ? ANAND_PC
    : LOCAL_API_URL;

export const APP_CLIENT = process.env.REACT_APP_CLIENT;
