import React from 'react';
import { FiSearch } from '../assets/icons/vander';

export default function FormSelect() {
  return (
    <>
      <form className='card-body text-start'>
        <div className='registration-form text-dark text-start'>
          <div className='row g-lg-0'>
            <div className='col-lg-8 col-md-6 col-12'>
              <div className='mb-3 mb-sm-0'>
                <label className='form-label d-none fs-6'>Search :</label>
                <div className='filter-search-form position-relative filter-border'>
                  <FiSearch className='fea icon-20 icons' />
                  <input
                    name='name'
                    type='text'
                    id='job-keyword'
                    className='form-control filter-input-box bg-light border-0'
                    placeholder='Search...'
                  />
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-12'>
              <input
                type='submit'
                id='search'
                name='search'
                style={{ height: '60px' }}
                className='btn btn-primary searchbtn w-100'
                value='Search'
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
