import cogoToast from 'cogo-toast';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import isMobile from 'is-mobile';
import { TOKEN_KEY } from '../config/config';

export const decodedToken = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (!token) return;
  const decoded = jwtDecode(token);
  return decoded;
};

export const isTokenExpired = () => {
  //   if (!decodedToken()) return true;
  const tokenExpiryTime = decodedToken()?.exp;
  const now = moment().unix();
  const isExpired = now > tokenExpiryTime;
  if (isExpired) handleLogout();
  return isExpired;
};

export const isLoggedIn = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  return token ? true : false;
};

export const handleLogout = (ref = null) => {
  Alert('error', 'Logging Out');
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem('persist:zapproo-config');
  if (ref) {
    window.location.href = `/login?ref=${ref}`;
    return;
  }
  window.location.href = '/login';
};

export const Alert = (type, payload, isCenter = false) => {
  const options = {
    position: isMobile() ? 'top-center' : 'bottom-left',
  };
  if (!payload) return;
  switch (type) {
    case 'success':
      cogoToast.success(`${payload}!`, options);
      break;
    case 'error':
      if (typeof payload === 'object') {
        payload?.map((error) => {
          return cogoToast.error(error.msg, options);
        });
      } else {
        cogoToast.error(`${payload}!`, options);
      }
      break;
    case 'info':
      cogoToast.info(`${payload}!`, options);
      break;
    case 'warning':
      cogoToast.custom(
        <div
          style={{
            background: 'yellow',
            position: 'absolute',
            width: '250px',
            transition: 'all 0.5s ease-out',
          }}>
          {payload}
        </div>,
        options
      );
      break;
    default:
      cogoToast.info(`${payload}!`, options);
      break;
  }
};

export const isEmptyObj = (obj) => {
  return Object.keys(obj).length === 0;
};

export const isObjEqual = (obj1, obj2) => {
  if (
    obj1 === null ||
    obj1 === undefined ||
    obj2 === null ||
    obj2 === undefined
  )
    return false;
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

export const handleGoogleError = async (error) => {
  switch (error.code) {
    case 'auth/invalid-email':
      Alert('error', 'Invalid Email ! Please try again');
      break;
    case 'auth/user-not-found':
      Alert('error', 'User not found ! Please check your email address');
      break;
    case 'auth/wrong-password':
      Alert('error', 'Wrong Password ! Please try again');
      break;
    case 'auth/user-disabled':
      Alert('error', 'User disabled ! Please Contact Admin');
      break;
    case 'auth/too-many-requests':
      Alert('error', 'Too many attempts ! Please try again later');
      break;
    case 'auth/email-already-in-use':
      Alert('error', 'Email already in use ! Please Login');
      break;
    case 'auth/api-error':
      Alert('error', 'Server Error. Please try again later');
      break;
    case 'auth/invalid-action-code':
      Alert('error', 'Link Expired. Please try again');
      break;
    case 'auth/invalid-credential':
      Alert('error', 'Invalid Credentials !');
      break;
    default:
      Alert('error', 'Something went wrong ! Please try again');
      break;
  }
};

// Function to mask email
function maskEmail(email) {
  const [localPart, domain] = email.split('@');
  if (localPart.length < 3) {
    return email;
  }
  const maskedLocalPart = `${localPart[0]}${'x'.repeat(localPart.length - 2)}${
    localPart[localPart.length - 1]
  }`;
  return `${maskedLocalPart}@${domain}`;
}

// Function to mask phone number (Example: Keep first 2 and last 2 digits, mask the middle)
function maskPhoneNumber(phone) {
  if (phone.length <= 4) {
    return phone; // If the phone number is too short, return as is
  }
  return `${phone.slice(0, 2)}${'x'.repeat(phone.length - 4)}${phone.slice(
    -2
  )}`;
}

// Function to mask URLs (Example: Mask the path after the domain)
function maskUrl(url) {
  try {
    const parsedUrl = new URL(url);
    const domain = parsedUrl.origin; // Keep the protocol and domain intact
    const maskedPath = parsedUrl.pathname.replace(/[^\/]/g, 'x'); // Mask everything except the slashes
    return `${domain}${maskedPath}`;
  } catch (e) {
    return url; // If it's not a valid URL, return it as is
  }
}

// Function to mask generic text
function maskGenericText(text) {
  if (text.length <= 4) {
    return text; // If the text is too short, return as is
  }

  // Keep the first and last 2 characters visible, mask the rest
  return `${text.slice(0, 2)}${'x'.repeat(text.length - 4)}${text.slice(-2)}`;
}

export const maskText = (text = 'xxxx', type) => {
  switch (type) {
    case 'email':
      return maskEmail(text);
    case 'phone':
      return maskPhoneNumber(text);
    case 'url':
      return maskUrl(text);
    case 'text':
      return maskGenericText(text);
    default:
      return text; // Return the text as is if type is not recognized
  }
};
